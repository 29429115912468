<template>
  <div class="notLoggedInCheckoutDetails">
    <div class="group pb-3">
      <span class="group-title d-block mb-20">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVR4nO2cy4uOURzHPzMYl1zLJblksLFzzYIVCpuh4W+QWFhKUZiSwUKIUsjCXmjKZYPZToaYaZQyKJemiXKZcTs6dU69TaaZd+Y8z/nNOb9PfTfv2/uc9/fp6bmcGyiKoiiKoiiKoihVUwtsBI4AN4EOoBf45dLrPrPfHQY2uN8ow2QO0AS8A0yVeQscB2YPt7EcqXNn5rcRCB6Yr8Ahd0ylguXAkwCCB6YNqK9sKGfWu+utKSg9wDoyZzXwpUDJPp+BlWTK/BHe8EaaN8A8MuROiZJ9bpEZuyNI9tlJJowHXkYU3QmMIwMaI0r2aSADWgSItveHpJkB9AsQ3QdMJ2EaBUj22UHCnBIg2OcECdMiQHAW1+lOAYJ9npMwvQIEV3Y2JUufAME+P0iYnwIE+9j/kiw9AgT7fCJhXgkQ7GP7W5LlsQDBPg9JmEsCBPtcIGH2CxDss5eEWStAsM8qEqYW+ChA8geghsS5IUD0dTKgUYDohlzGDN9HvmxMIBPORBR9moxYHGlIy/ZvLCEzrkQQfZlMZ5D2lyjZtrWUTGkuUXTSY4RDMQV4XdIEx6lkznbgb4GS7bG3xS4yh8e95tjFSaKuoL7qRzm9nFQzXaw98HSCWcNuPTMWBhS9IHYx0jGBogyBii4Jo2e0ik4Ko2e0ik4Ko2d08WwOKNoeSxmkJ68toOg2d0ylArtC6kEBfR33gWmVDeVKjVuuXOTie7srza4cJsz8j7nAvpLXtHS4Nm3byXcY7QFuu82nTKT8AVqBg8AyEsEOhh5whRU5ijKavACOAmsYY6wATgJdAiSaKtPl/rutQSQ1bk5dqwBZJlBaXU1ibqJbgacCxJiC0u5qjMZM4JoAEaakXHU1l77x1DMBxZuSYx9HF5X5JNEtoGgTKd1lTC2bGLg/wozR2HvSpCJFnxNQpBGSs0VJro/8RmeE5XdRb5YXBRRnhOV8aMm2X/e7gMKMsFgnk0OK3iKgKCM0m0KKbhJQkBGaYyFF3xNQkBGauyFFp9yXYUYZu5N7MMrc59mMsdhlG8HQJw4GFW3dKIqiKIqiKIqiMGb5BzafOwTQDlQbAAAAAElFTkSuQmCC"
          width="45"> {{ $t("my_information") }}</span>
      <b-form-group :label="$t('form_email') + '*'" label-for="email"
        description="Die Bestätigung wird an diese E-Mail-Adresse  gesendet">
        <b-form-input id="email" :placeholder="$t('form_email')" v-model="form.email" type="text"
          :state="form.emailState" aria-describedby="email-feedback" autocomplete="off"></b-form-input>
        <b-form-invalid-feedback id="email-feedback">
          {{ form.emailError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-checkbox id="createAccount" v-model="createAccount" name="createAccount" class="pl-30">
        {{ $t("Ich möchte ein Konto erstellen") }}</b-form-checkbox>

      <div v-if="createAccount">
        <span class="group-title d-block mb-20">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADJElEQVR4nO2cy4uOURzHPzMYl1zLJblksLFzzYIVCpuh4W+QWFhKUZiSwUKIUsjCXmjKZYPZToaYaZQyKJemiXKZcTs6dU69TaaZd+Y8z/nNOb9PfTfv2/uc9/fp6bmcGyiKoiiKoiiKoihVUwtsBI4AN4EOoBf45dLrPrPfHQY2uN8ow2QO0AS8A0yVeQscB2YPt7EcqXNn5rcRCB6Yr8Ahd0ylguXAkwCCB6YNqK9sKGfWu+utKSg9wDoyZzXwpUDJPp+BlWTK/BHe8EaaN8A8MuROiZJ9bpEZuyNI9tlJJowHXkYU3QmMIwMaI0r2aSADWgSItveHpJkB9AsQ3QdMJ2EaBUj22UHCnBIg2OcECdMiQHAW1+lOAYJ9npMwvQIEV3Y2JUufAME+P0iYnwIE+9j/kiw9AgT7fCJhXgkQ7GP7W5LlsQDBPg9JmEsCBPtcIGH2CxDss5eEWStAsM8qEqYW+ChA8geghsS5IUD0dTKgUYDohlzGDN9HvmxMIBPORBR9moxYHGlIy/ZvLCEzrkQQfZlMZ5D2lyjZtrWUTGkuUXTSY4RDMQV4XdIEx6lkznbgb4GS7bG3xS4yh8e95tjFSaKuoL7qRzm9nFQzXaw98HSCWcNuPTMWBhS9IHYx0jGBogyBii4Jo2e0ik4Ko2e0ik4Ko2d08WwOKNoeSxmkJ68toOg2d0ylArtC6kEBfR33gWmVDeVKjVuuXOTie7srza4cJsz8j7nAvpLXtHS4Nm3byXcY7QFuu82nTKT8AVqBg8AyEsEOhh5whRU5ijKavACOAmsYY6wATgJdAiSaKtPl/rutQSQ1bk5dqwBZJlBaXU1ibqJbgacCxJiC0u5qjMZM4JoAEaakXHU1l77x1DMBxZuSYx9HF5X5JNEtoGgTKd1lTC2bGLg/wozR2HvSpCJFnxNQpBGSs0VJro/8RmeE5XdRb5YXBRRnhOV8aMm2X/e7gMKMsFgnk0OK3iKgKCM0m0KKbhJQkBGaYyFF3xNQkBGauyFFp9yXYUYZu5N7MMrc59mMsdhlG8HQJw4GFW3dKIqiKIqiKIqiMGb5BzafOwTQDlQbAAAAAElFTkSuQmCC"
            width="45">
          {{ $t("Nieuw account") }}</span>

        <b-form-group :label="$t('Wachtwoord') + '*'" label-for="Wachtwoord">
          <b-form-input id="createAccountFormPassword" :placeholder="$t('Wachtwoord')"
            v-model="createAccountForm.password" type="password" :state="createAccountForm.passwordState"
            aria-describedby="password-feedback" autocomplete="off"></b-form-input>
          <b-form-invalid-feedback id="password-feedback">
            {{ form.emailError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :label="$t('Bevestig wachtwoord') + '*'" label-for="Bevestig wachtwoord">
          <b-form-input id="confirmPassword" :placeholder="$t('Bevestig wachtwoord')"
            v-model="createAccountForm.confirmPassword" type="password" :state="createAccountForm.confirmPasswordState"
            aria-describedby="confirm-password-feedback" autocomplete="off"></b-form-input>
          <b-form-invalid-feedback id="confirm-password-feedback">
            {{ createAccountForm.confirmPasswordError }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-checkbox v-model="createAccountForm.isCompany" name="createAccountForm.isCompany" class="pl-30">
          {{ $t("Bent u een bedrijf?") }}</b-form-checkbox>

        <div v-if="createAccountForm.isCompany">

          <b-form-group :label="$t('Bedrijfsnaam') + '*'" label-for="Bedrijfsnaam">
            <b-form-input id="confirmPassword" :placeholder="$t('Bedrijfsnaam')" v-model="createAccountForm.companyName"
              type="password" :state="createAccountForm.companyNameState" aria-describedby="confirm-password-feedback"
              autocomplete="off"></b-form-input>
            <b-form-invalid-feedback id="confirm-password-feedback">
              {{ createAccountForm.companyNameError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('KvK nummer') + '*'" label-for="KvK nummer">
            <b-form-input id="CommerceNumber" :placeholder="$t('KvK nummer')"
              v-model="createAccountForm.CommerceNumber" type="password"
              :state="createAccountForm.CommerceNumberState" aria-describedby="confirm-password-feedback"
              autocomplete="off"></b-form-input>
            <b-form-invalid-feedback id="confirm-password-feedback">
              {{ createAccountForm.CommerceNumberError }}         
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('BTW nummer') + '*'" label-for="BTW nummer">
            <b-form-input id="vatNumber" :placeholder="$t('BTW nummer')"
              v-model="createAccountForm.vatNumber" type="password"
              :state="createAccountForm.vatNumberState" aria-describedby="confirm-password-feedback"
              autocomplete="off"></b-form-input>
            <b-form-invalid-feedback id="confirm-password-feedback">
              {{ createAccountForm.vatNumberError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('Voornaam') + '*'" label-for="Voornaam">
            <b-form-input id="firstName" :placeholder="$t('Voornaam')" v-model="createAccountForm.firstName"
              type="password" :state="createAccountForm.firstNameState"
              aria-describedby="confirm-password-feedback" autocomplete="off"></b-form-input>
            <b-form-invalid-feedback id="confirm-password-feedback">
              {{ createAccountForm.firstNameError }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('Achternaam') + '*'" label-for="Achternaam">
            <b-form-input id="lastName" :placeholder="$t('Achternaam')"
              v-model="createAccountForm.lastName" type="password"
              :state="createAccountForm.lastNameState" aria-describedby="confirm-password-feedback"
              autocomplete="off"></b-form-input>
            <b-form-invalid-feedback id="confirm-password-feedback">
              {{ createAccountForm.lastNameError }}
            </b-form-invalid-feedback>
          </b-form-group>

        </div>


      </div>
      <div v-if="emailExists">
        <b-form-group class="account-inputs" id="password-group" :label="$t('form_password') + '*'"
          label-for="password">
          <b-form-input id="password" v-model="form.password" :placeholder="$t('form_password')" type="password"
            aria-describedby="password-feedback" autocomplete="off"></b-form-input>
          <b-form-invalid-feedback id="password-feedback">
            {{ form.passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex justify-content-between align-items-center">
          <b-link to="/forgot-password" class="font-weight-normal link-underline">{{ $t("password_forgot") }}</b-link>
          <b-button type="button" variant="info" @click="doLogin" class="text-white ml-20 form-submit-btn">{{
            $t("login") }}</b-button>
        </div>
      </div>
    </div>


    <div class="group">
      <span class="group-title d-block mb-25">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVR4nO2cPWsUURSGH4hELSLaqtiJYBcbf4J2ttqIlZUfpa2dEf0DVhYK1lrpH/CjMVYSsFJMLIMGVFA8MjALcVw2ye6de9+5+z7wNlkyc87D5WTPTlgwxhhjjDH/cgC4C2wAX4CV9mcmIUeB10B08hY4YdNpWAY+jpE8yjpw1rJn4yLwfYLkUX4CVyx77yy0Mzj2mAfAvinuN5csAU+nkDzKc+Bw6SbUOQWszSB5lLX2WjuxuO2dTHfur7SvV8d5YDOB5FE222tOYqfx1LxeFVeBXwklj/IbuDXhvt2T3E3zfr0K9gMPexDczRPg4Jj77+Z3q11CoqeMW26qF728wxLSV7rLTdWiL+1yCekr25ebKkUvAPcLCu7mXo2il4BnAnKnyWA4CbwXEFa16HOJl5Cw6P+52S4MMfAw70tIzLPo3EtIzKPoM8AnATFRs+jLwA8BKdFDmt4G+yQkBpaiT24ODXgJiSnyAjiSW/LQl5CYMh+A00N9EhIDyzfgQt+Sa1lCYsb8AW73Ibh5SPlYoMEQy6PUD3DvCDQVomncJGNdoKEQTfOA16LpX/TnlKLnYSkJhdGx2Mr2CKHMfzPFnKUYpRsPiy4vJXyiKS7MowPteEZj0cVPoU805cV5dFBe6iBmtK+TGIvOhEVnwqIzYdGZsOhMWHQmLDoTFp0Ji86ERWfCojOh9iFOiNWTDLXGQqyeZKg1FmL1JEOtsRCrJxlqjYVYPclQayzE6kmGWmMhVk8y1BoLsXqSodZYiNWTDLXGQqyeZKg1FmL1JEOtsRCrJxlbGZoLkXwtKfqNgIDIlFclRd8QEBCZcq301/q8E5AQPWdV4SuOj1cuexU4hgjNyb7ezrEa/kBuAS/bcVH8JBtjjDHGGGOMMcYgxl+nBIeTfLmCTQAAAABJRU5ErkJggg=="
          width="45" />{{ $t("shipping_address") }}</span>
      <b-form-checkbox id="isCompany" v-model="isCompany" name="isCompany" class="pl-30">
        {{ $t("form_are_you_a_company") }}</b-form-checkbox>
      <div v-if="isCompany" class="short-form">
        <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name') + '*'"
          label-for="company">
          <b-form-input id="company" :placeholder="$t('form_company_name')" v-model="form.company" type="text"
            v-on:blur="validateCompany" :state="form.companyState" aria-describedby="company-feedback"></b-form-input>
          <b-form-invalid-feedback id="company-feedback">
            {{ form.companyError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!-- <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number')" label-for="taxvat">
          <b-form-input id="taxvat" v-model="form.taxvat" type="text" :state="form.taxvatState" v-on:blur="validateVAT"
            aria-describedby="taxvat-feedback"></b-form-input>
          <b-form-invalid-feedback id="taxvat-feedback">
            {{ form.taxvatError }}
          </b-form-invalid-feedback>
        </b-form-group> -->
      </div>

      <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name') + '*'"
        label-for="first_name">
        <b-form-input id="first_name" :placeholder="$t('form_first_name')" v-model="form.firstname" type="text"
          v-on:blur="validateFirstname" :state="form.firstnameState"
          aria-describedby="firstname-feedback"></b-form-input>
        <b-form-invalid-feedback id="firstname-feedback">
          {{ form.firstnameError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name') + '*'"
        label-for="last_name">
        <b-form-input id="last_name" :placeholder="$t('form_last_name')" v-model="form.lastname" type="text"
          v-on:blur="validateLastname" :state="form.lastnameState" aria-describedby="lastname-feedback"></b-form-input>
        <b-form-invalid-feedback id="lastname-feedback">
          {{ form.lastnameError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="account-inputs" id="country-group" :label="$t('form_country') + '*'" label-for="country">
        <b-form-select id="country" v-model="form.country_code" :options="countries" class="select"></b-form-select>
      </b-form-group>

      <b-form-group class="account-inputs" id="address-group" :label="$t('address') + '*'" label-for="address">
        <b-form-input id="address" v-model="form.addressDisplay" :placeholder="$t('address')" type="text"
          :state="form.addressDisplayState" aria-describedby="addressDisplay-feedback"></b-form-input>
        <b-form-invalid-feedback id="addressDisplay-feedback">
          {{ form.addressDisplayError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="account-inputs" id="house_number-group"
        :label="$t('form_house_number') + ' + ' + $t('form_addition') + '*'" label-for="house_number">
        <b-form-input id="house_number" :placeholder="$t('form_house_number') + ' + ' + $t('form_addition')"
          v-model="form.address.house_number" type="text" v-on:blur="validateHouseNumber"
          :state="form.address.house_numberState" aria-describedby="address.house_number-feedback"
          ref="addressHouseNumber"></b-form-input>
        <b-form-invalid-feedback id="address.house_number-feedback">
          {{ form.address.house_numberError }}
        </b-form-invalid-feedback>
      </b-form-group>



      <b-form-group class="account-inputs" id="postcode-group" :label="$t('form_postal_code') + '*'"
        label-for="postcode">
        <b-form-input id="postcode" :placeholder="$t('form_postal_code')" v-model="form.address.postcode" type="text"
          :state="form.postcodeState" aria-describedby="address.postcode-feedback" v-on:blur="validatePostcode"
          ref="addressPostcode"></b-form-input>
        <b-form-invalid-feedback id="address.postcode-feedback">
          {{ form.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- <b-form-group class="account-inputs" id="addition-group" :label="$t('form_addition')" label-for="addition">
        <b-form-input id="addition" v-model="form.address.addition" type="text"
          :readonly="form.address.additionDisabled"></b-form-input>
      </b-form-group> -->

      <!-- <b-form-group class="account-inputs" id="postcode-group1" :label="$t('form_postal_code') + '*'"
        label-for="postcode1">
        <b-form-input id="postcode1" v-model="form.address.postcode" type="text" v-on:blur="validatePostcode"
          :state="form.postcodeState" aria-describedby="address.postcode1-feedback"></b-form-input>
        <b-form-invalid-feedback id="address.postcode1-feedback">
          {{ form.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group> -->

      <b-form-group class="account-inputs" id="city-group" :label="$t('form_city') + '*'" label-for="city">
        <b-form-input id="city" :placeholder="$t('form_city')" v-model="form.address.city" type="text" v-on:blur="validateCity"
          :state="form.cityState" aria-describedby="address.city-feedback"></b-form-input>
        <b-form-invalid-feedback id="address.city-feedback">
          {{ form.cityError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- <b-form-group class="account-inputs" id="add-group" label=" " label-for="add">
        <div id="add">
          {{ foundAddress }}
        </div>
      </b-form-group> -->

      <!-- <b-form-group class="account-inputs" id="addm-group" label=" " label-for="addm">
        <div id="addm">
          <b-form-radio style="width: 300px" class="account-radios" v-model="selectedAddress"
            v-for="(item, index) in foundAddresses" :key="`nl-${index}`" name="some-radios" :value="index">{{ item.text
            }}</b-form-radio>
        </div>
      </b-form-group> -->

      <!-- <b-form-checkbox name="manual" class="pl-30">{{
        $t("form_fillout_address_manual") }}</b-form-checkbox> -->

      <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone') + '*'" label-for="telephone">
        <b-form-input :placeholder="$t('form_phone')" id="telephone" v-model="form.address.telephone" type="text"
          v-on:blur="validatePhone" :state="form.telephoneState"
          aria-describedby="address.telephone-feedback"></b-form-input>
        <b-form-invalid-feedback id="address.telephone-feedback">
          {{ form.telephoneError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-checkbox id="checkbox-2" name="checkbox-2" label-for="checkbox2" v-model="form.default_billing"
        class="pl-30">{{
          $t("form_address_also_invoice_address") }}</b-form-checkbox>
    </div>
    <div class="group" v-if="form.default_billing == false">
      <span class="group-title d-block mb-25"><i class="lnr lnr-home mr-10"></i>{{ $t("invoice_address") }}</span>
      <b-form-checkbox id="checkbox-99" v-model="isInvoiceCompany" name="checkbox-99">{{ $t("form_are_you_a_company")
        }}</b-form-checkbox>
      <div v-if="isInvoiceCompany" class="short-form">
        <b-form-group class="account-inputs" id="company-group" :label="$t('form_company_name') + '*'"
          label-for="company">
          <b-form-input :placeholder="$t('form_company_name')" id="company" v-model="form.invoice.company" type="text"
            v-on:blur="validateInvoiceCompany" :state="form.invoice.companyState"
            aria-describedby="company-feedback"></b-form-input>
          <b-form-invalid-feedback id="company-feedback">
            {{ form.invoice.companyError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!--         <b-form-group
          class="account-inputs"
          id="coc_number-group"
          :label="$t('form_coc_number') + '*'"
          label-for="coc_number"
        >
          <b-form-input
            id="coc_number"
            v-model="form.invoice.coc_number"
            type="text"
            v-on:blur="validateInvoiceCOCNumber"
            :state="form.invoice.coc_numberState"
            aria-describedby="coc_number-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="coc_number-feedback">
            {{ form.invoice.coc_numberError }}
          </b-form-invalid-feedback>
        </b-form-group>
 -->
        <b-form-group class="account-inputs" id="taxvat-group" :label="$t('form_vat_number')" label-for="taxvat">
          <b-form-input :placeholder="$t('form_vat_number')" id="taxvat" v-model="form.invoice.taxvat" type="text"
            :state="form.invoice.taxvatState" v-on:blur="validateInvoiceVAT"
            aria-describedby="taxvat-feedback"></b-form-input>
          <b-form-invalid-feedback id="taxvat-feedback">
            {{ form.invoice.taxvatError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group class="account-inputs" id="first_name-group" :label="$t('form_first_name') + '*'"
        label-for="first_name">
        <b-form-input :placeholder="$t('form_first_name')" id="first_name" v-model="form.invoice.firstname" type="text"
          v-on:blur="validateInvoiceFirstname" :state="form.invoice.firstnameState"
          aria-describedby="firstname-feedback"></b-form-input>
        <b-form-invalid-feedback id="firstname-feedback">
          {{ form.invoice.firstnameError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="account-inputs" id="last_name-group" :label="$t('form_last_name') + '*'"
        label-for="last_name">
        <b-form-input :placeholder="$t('form_last_name')" id="last_name" v-model="form.invoice.lastname" type="text"
          v-on:blur="validateInvoiceLastname" :state="form.invoice.lastnameState"
          aria-describedby="lastname-feedback"></b-form-input>
        <b-form-invalid-feedback id="lastname-feedback">
          {{ form.invoice.lastnameError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="account-inputs" id="country-group" :label="$t('form_country') + '*'" label-for="country">
        <b-form-select id="country" v-model="form.invoice.address.country_code" :options="countries"
          class="select"></b-form-select>
      </b-form-group>
      <b-form-group v-if="!showFullInvoiceAddress" class="account-inputs" id="postcode-group"
        :label="$t('form_postal_code') + '*'" label-for="postcode">
        <b-form-input :placeholder="$t('form_postal_code')" id="postcode" v-model="form.invoice.address.postcode"
          type="text" :state="form.invoice.address.postcodeState" aria-describedby="address.postcode-feedback"
          v-on:blur="validateInvoicePostcode"></b-form-input>
        <b-form-invalid-feedback id="address.postcode-feedback">
          {{ form.invoice.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="street-group"
        :label="$t('form_street') + '*'" label-for="street">
        <b-form-input :placeholder="$t('form_street')" id="street" v-model="form.invoice.address.streetDisplay"
          type="text" v-on:blur="validateInvoiceStreet" :state="form.invoice.address.streetDisplayState"
          aria-describedby="address.streetDisplay-feedback"></b-form-input>
        <b-form-invalid-feedback id="address.streetDisplay-feedback">
          {{ form.invoice.address.streetDisplayError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col sm="6" class="first">
          <b-form-group class="account-inputs" id="house_number-group" :label="$t('form_house_number') + '*'"
            label-for="house_number">
            <b-form-input :placeholder="$t('form_house_number')" id="house_number"
              v-model="form.invoice.address.house_number" type="text" v-on:blur="validateInvoiceHouseNumber"
              :state="form.invoice.address.house_numberState"
              aria-describedby="address.house_number-feedback"></b-form-input>
            <b-form-invalid-feedback id="address.house_number-feedback">
              {{ form.invoice.address.house_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6" class="third">
          <b-form-group class="account-inputs" id="addition-group" :label="$t('form_addition')" label-for="addition">
            <b-form-input :placeholder="$t('form_addition')" id="addition" v-model="form.invoice.address.addition"
              type="text"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="postcode-group1"
        :label="$t('form_postal_code') + '*'" label-for="postcode1">
        <b-form-input :placeholder="$t('form_postal_code')" id="postcode1" v-model="form.invoice.address.postcode"
          type="text" v-on:blur="validateInvoicePostcode" :state="form.invoice.address.postcodeState"
          aria-describedby="address.postcode1-feedback"></b-form-input>
        <b-form-invalid-feedback id="address.postcode1-feedback">
          {{ form.invoice.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group v-if="showFullInvoiceAddress" class="account-inputs" id="city-group" :label="$t('form_city') + '*'"
        label-for="city">
        <b-form-input :placeholder="$t('form_city')" id="city" v-model="form.invoice.address.city" type="text"
          v-on:blur="validateInvoiceCity" :state="form.invoice.address.cityState"
          aria-describedby="address.city-feedback"></b-form-input>
        <b-form-invalid-feedback id="address.city-feedback">
          {{ form.invoice.address.cityError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group v-if="foundInvoiceAddress != '' && showFullInvoiceAddress == false" class="account-inputs"
        id="add-group" label=" " label-for="add">
        <div id="add">
          {{ foundInvoiceAddress }}
        </div>
      </b-form-group>
      <b-form-group v-if="
        foundInvoiceAddresses.length > 0 && showFullInvoiceAddress == false
      " class="account-inputs" id="addm-group" label=" " label-for="addm">
        <div id="addm">
          <b-form-radio style="width: 300px" class="account-radios" v-model="selectedInvoiceAddress"
            v-for="(item, index) in foundInvoiceAddresses" :key="`add-${index}`" name="some-radios" :value="index">{{
              item.text }}</b-form-radio>
        </div>
      </b-form-group>
      <b-form-checkbox v-if="showManual" id="manual2" v-model="showFullInvoiceAddress" name="manual2">{{
        $t("form_fillout_address_manual") }}</b-form-checkbox>
      <b-form-group class="account-inputs" id="telephone-group" :label="$t('form_phone') + '*'" label-for="telephone">
        <b-form-input :placeholder="$t('form_phone')" id="telephone" v-model="form.invoice.address.telephone"
          type="text" v-on:blur="validateInvoicePhone" :state="form.invoice.address.telephoneState"
          aria-describedby="address.telephone-feedback"></b-form-input>
        <b-form-invalid-feedback id="address.telephone-feedback">
          {{ form.invoice.address.telephoneError }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import config from "@config";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "NotLoggedInCheckoutDetails",
  mixins: [Cart],
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
    this.storage();
    this.$root.$on("checkFields", () => {
      this.checkFields();
    });
  },
  methods: {
    async storage() {
      this.localStorageData =
        (await JSON.parse(localStorage.getItem("checkoutAddress"))) ?? {};

      this.form.email = this.localStorageData.email
        ? this.localStorageData.email
        : "";
      this.form.firstname = this.localStorageData.firstname
        ? this.localStorageData.firstname
        : "";
      this.form.lastname = this.localStorageData.lastname
        ? this.localStorageData.lastname
        : "";
      this.form.firstnameState = this.localStorageData.firstnameState
        ? this.localStorageData.firstnameState
        : "";
      this.form.lastnameState = this.localStorageData.lastnameState
        ? this.localStorageData.lastnameState
        : "";
      this.form.address = {
        country_code:
          this.localStorageData.country_code ?? config.customers.defaultCountry,
        postcode: this.localStorageData.postcode
          ? this.localStorageData.postcode
          : "",
        postcodeState: null,
        postcodeError: "",
        postcodeTimer: null,

        street: this.localStorageData.street
          ? this.localStorageData.street
          : "",
        streetDisplay: this.localStorageData.streetDisplay
          ? this.localStorageData.streetDisplay
          : "",
        streetDisplayState: null,
        streetDisplayError: "",
        streetDisplayTimer: null,

        house_number: this.localStorageData.house_number
          ? this.localStorageData.house_number
          : "",
        house_numberState: null,
        house_numberError: "",
        house_numberTimer: null,

        addition: this.localStorageData.addition
          ? this.localStorageData.addition
          : "",
        city: this.localStorageData.city ? this.localStorageData.city : "",
        cityState: null,
        cityError: "",
        cityTimer: null,

        telephone: this.localStorageData.telephone
          ? this.localStorageData.telephone
          : "",
        telephoneState: this.localStorageData.telephoneState
          ? this.localStorageData.telephoneState
          : null,
        telephoneError: "",
        telephoneTimer: null,
        default_billing: this.localStorageData.default_billing
          ? this.localStorageData.default_billing
          : true,
        default_shipping: this.localStorageData.default_shipping
          ? this.localStorageData.default_shipping
          : true,
      };
      this.form.invoice.address =
        this.localStorageData.invoice && this.localStorageData.invoice.address
          ? this.localStorageData.invoice.address
          : {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          };
      // this.shippingAddress = this.localStorageData.addressData;
      // this.invoiceAddress = this.localStorageData.invoiceAddressData;
    },
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.emailExists = true;
      } else {
        this.emailExists = false;
      }

      this.form.emailState = true;
      this.updateLocalStorage({
        email: this.form.email,
        emailState: this.form.emailState,
      });
      this.$store.dispatch("cart/addCartEmail", {
        email: this.form.email,
      });
      return true;
    },
    async doLogin() {
      const loginOk = await this.$store.dispatch("user/login", {
        username: this.form.email,
        password: this.form.password,
      });

      if (loginOk == true) {
        this.clearLocalStorage();
        const msg = {
          type: "success",
          title: this.$t("login_success"),
          text: this.$t("you_are_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    async validateVAT(event) {
      if (this.isCompany == true) {
        if (this.form.taxvat != "") {
          if (
            this.form.taxvat.substring(0, 2).toUpperCase() ===
            this.form.country_code
          ) {
            const retval = await validateVatNumber(this.form.taxvat);
            if (retval.valid == "true") {
              this.isValidVatNumber = true;
              this.form.taxvatState = true;
              this.updateLocalStorage({
                taxvat: this.form.taxvat,
                taxvatState: this.form.taxvatState,
              });
            } else {
              this.isValidVatNumber = false;
              this.form.taxvatState = false;
              this.form.taxvatError = this.$t("taxvat_invalid");

              this.showVatMessage = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.taxvatState = null;
      }
      this.validateInput(event, this.form.taxvatState);
    },
    async validateAddress() {
      let isOk = true;
      this.validatePostcode();
      this.validateHouseNumber();
      this.validateCity();
      this.validateStreet();
      this.validatePhone();
      if (this.isCompany == true) {
        if (!this.form.companyState) {
          isOk = false;
          console.log("company error");
        }
        this.addressState.companyState = this.form.companyState;
      }
      if (!this.form.firstnameState) {
        isOk = false;
        console.log("first name error");
      }
      this.addressState.firstnameState = this.form.firstnameState;
      if (!this.form.lastnameState) {
        isOk = false;
        console.log("last name error");
      }
      this.addressState.lastnameState = this.form.lastnameState;

      if (!this.form.address) {
        isOk = false;
        this.addressState.address = !!this.form.address;
      } else {
        if (
          !this.form.country_code ||
          this.form.country_code == ""
        ) {
          this.addressState.country_code = false;
          isOk = false;
        } else {
          this.addressState.country_code = true;
        }
        if (!this.form.postcodeState) {
          isOk = false;
          console.log("postcode error");
        }
        this.addressState.postcodeState = this.form.postcodeState;

        if (!this.showFullAddress) {
          if (!this.form.address.streetDisplayState) {
            isOk = false;
            console.log("street error");
          }
          this.addressState.streetDisplayState =
            this.form.address.streetDisplayState;
        }
        if (!this.form.address.house_numberState) {
          isOk = false;
          console.log("house number error");
        }
        this.addressState.house_numberState =
          this.form.address.house_numberState;

        if (!this.form.address.cityState) {
          isOk = false;
          console.log("city error");
        }
        this.addressState.cityState = this.form.address.cityState;

        if (!this.form.address.telephoneState) {
          isOk = false;
          console.log("phone error");
        }
        this.addressState.telephoneState = this.form.address.telephoneState;
      }
      this.addressState.isOk = isOk;
      if (isOk == true) {
        const address = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: `${this.form.address.streetDisplay} ${this.form.address.house_number}${this.form.address.addition}`,
          country_code: this.form.country_code,
        };
        if (this.isCompany == true) {
          address.company = this.form.company;
          if (this.form.taxvat != "") {
            address.vat_id = this.form.taxvat;
          }
        }
        await this.$store.dispatch("cart/addShippingAddress", {
          address: address,
          reloadCart: false,
        });
        if (this.form.address.default_billing == true) {
          await this.$store.dispatch("cart/addBillingAddress", {
            address: address,
            reloadCart: true,
          });
        }
      } else {
        this.$store.commit("cart/setShippingMethods", []);
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (!this.form.invoice.companyState) {
          isOk = false;
        }
      }
      if (!this.form.invoice.firstnameState) {
        isOk = false;
      }
      if (this.form.invoice.lastname == "") {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode == "") {
        isOk = false;
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }

      if (isOk == true) {
        const address = {
          company: this.form.invoice.company,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: `${this.form.invoice.address.streetDisplay} ${this.form.invoice.address.house_number}${this.form.invoice.address.addition}`,
          country_code: this.form.invoice.address.country_code,
        };
        if (this.form.invoice.isCompany == true) {
          address.company = this.form.invoice.company;
          if (this.form.invoice.taxvat != "") {
            address.vat_id = this.form.invoice.taxvat;
          }
        }

        await this.$store.dispatch("cart/addBillingAddress", {
          address: address,
          reloadCart: true,
        });
      } else {
        this.$store.commit("cart/setShippingMethods", []);
      }
    },
    validateCompany(event) {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
          this.updateLocalStorage({
            company: this.form.company,
            companyState: this.form.companyState,
          });
        }
      } else {
        this.form.companyState = null;
      }
      this.validateInput(event, this.form.companyState);
    },
    validateFirstname(event) {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
        this.updateLocalStorage({
          firstname: this.form.firstname,
          firstnameState: this.form.firstnameState,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceFirstname: this.form.firstname,
            invoiceFirstnameState: this.form.firstnameState,
          });
        }
      }
      this.validateInput(event, this.form.firstnameState);
    },
    validateLastname(event) {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
        this.updateLocalStorage({
          lastname: this.form.lastname,
          lastnameState: this.form.lastnameState,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({
            invoiceFirstname: this.form.lastname,
            invoiceFirstnameState: this.form.lastnameState,
          });
        }
      }
      this.validateInput(event, this.form.lastnameState);
    },
    validateCOCNumber(event) {
      if (this.isCompany == true) {
        if (this.form.coc_number == "") {
          this.form.coc_numberState = false;
          this.form.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.coc_numberState = true;
          this.updateLocalStorage({
            coc: this.form.coc_number,
            cocState: this.form.coc_numberState,
          });
          if (this.form.address.default_billing) {
            this.updateLocalStorage({
              invoiceCoc: this.form.coc_number,
              invoiceCocState: this.form.coc_numberState,
            });
          }
        }
      } else {
        this.form.coc_numberState = null;
      }
      this.validateInput(event, this.form.coc_numberState);
    },
    validateStreet(event) {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
        this.updateLocalStorage({
          streetDisplay: this.form.address.streetDisplay,
          streetDisplayState: this.form.address.streetDisplayState,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({ invoiceAddress: this.form.address });
        }
      }
      this.validateInput(event, this.form.address.streetDisplayState);
    },
    validateHouseNumber(event) {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
        this.updateLocalStorage({
          house_number: this.form.address.house_number,
          house_numberState: this.form.address.house_numberState,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({ invoiceAddress: this.form.address });
        }
      }
      this.validateInput(event, this.form.address.house_numberState);
    },
    validateCity(event) {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
        this.updateLocalStorage({
          city: this.form.address.city,
          cityState: this.form.address.cityState,
        });
        if (this.form.address.default_billing) {
          this.updateLocalStorage({ invoiceAddress: this.form.address });
        }
      }
      this.validateInput(event, this.form.address.cityState);
    },
    validatePhone(event) {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
        this.updateLocalStorage({
          telephone: this.form.address.telephone,
          telephoneState: this.form.address.telephoneState,
        });
        1;
        if (this.form.address.default_billing) {
          this.updateLocalStorage({ invoiceAddress: this.form.address });
        }
      }
      this.validateInput(event, this.form.address.telephoneState);
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.address.addition = "";
          }
          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.foundAddress = address;
          this.validatePostcode();
          this.validateHouseNumber();
          this.form.postcodeState = true;
          this.form.address.house_numberState = true;
          this.form.address.cityState = true;
          this.form.address.streetDisplayState = true;

          this.updateLocalStorage({
            postcode: this.form.address.postcode,
            postcodeState: this.form.postcodeState,
            city: this.form.address.city,
            cityState: this.form.address.cityState,
            house_number: this.form.address.house_number,
            house_numberState: this.form.address.house_numberState,
          });
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
          this.form.postcodeState = false;
          this.form.address.house_numberState = false;
          this.$refs.addressPostcode.$el.parentElement.classList.remove(
            "valid"
          );
          this.$refs.addressHouseNumber.$el.parentElement.classList.remove(
            "valid"
          );
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + "-" + item.letter;
            }
            if (item.addition != null) {
              address =
                address + (item.letter == null ? "-" : "") + item.addition;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.postcodeState = true;
          this.updateLocalStorage({
            postcode: this.form.address.postcode,
            postcodeState: this.form.postcodeState,
          });
          if (this.form.address.default_billing) {
            this.updateLocalStorage({ invoiceAddress: this.form.address });
          }
        }
      }
    },
    validatePostcode(event) {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.country_code == "NL" &&
        nl.test(this.form.address.postcode)
      ) {
        this.form.postcodeState = true;
        this.updateLocalStorage({
          postcode: this.form.address.postcode,
          postcodeState: this.form.postcodeState,
        });
      } else if (
        this.form.country_code == "BE" &&
        be.test(this.form.address.postcode)
      ) {
        this.form.postcodeState = true;
        this.updateLocalStorage({
          postcode: this.form.address.postcode,
          postcodeState: this.form.postcodeState,
        });
      } else if (
        this.form.country_code == "DE" &&
        de.test(this.form.address.postcode)
      ) {
        this.form.postcodeState = true;
        this.updateLocalStorage({
          postcode: this.form.address.postcode,
          postcodeState: this.form.postcodeState,
        });
      } else {
        this.form.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      }
      this.validateInput(event, this.form.postcodeState);
    },
    async validateInvoiceVAT(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.taxvat != "") {
          if (
            this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
            this.form.invoice.address.country_code
          ) {
            const retval = await validateVatNumber(this.form.invoice.taxvat);
            if (retval.valid == "true") {
              this.isValidInvoiceVatNumber = true;
              this.form.invoice.taxvatState = true;
              this.updateLocalStorage({
                invoiceVat: this.form.invoice.taxvat,
                invoiceVatState: this.form.invoice.taxvatState,
              });
            } else {
              this.isValidInvoiceVatNumber = false;
              this.form.invoice.taxvatState = false;
              this.form.invoice.taxvatError = this.$t("taxvat_invalid");
            }
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
          }
        } else {
          this.form.taxvatState = null;
          this.form.taxvatError = "";
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
      this.validateInput(event, this.form.invoice.taxvatState);
    },
    validateInvoiceCompany(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
          this.updateLocalStorage({
            invoiceFirstName: this.form.invoice.company,
            invoiceFirstNameState: this.form.invoice.company,
          });
        }
      } else {
        this.form.invoice.companyState = null;
      }
      this.validateInput(event, this.form.invoice.companyState);
    },
    validateInvoiceFirstname(event) {
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
        this.updateLocalStorage({
          invoiceFirstName: this.form.invoice.firstname,
          invoiceFirstNameState: this.form.invoice.firstnameState,
        });
      }
      this.validateInput(event, this.form.invoice.firstnameState);
    },
    validateInvoiceLastname(event) {
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
        this.updateLocalStorage({
          invoiceLastName: this.form.invoice.lastname,
          invoiceLastnameState: this.form.invoice.lastnameState,
        });
      }
      this.validateInput(event, this.form.invoice.lastnameState);
    },
    validateInvoiceCOCNumber(event) {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.coc_number == "") {
          this.form.invoice.coc_numberState = false;
          this.form.invoice.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.invoice.coc_numberState = true;
          this.updateLocalStorage({
            invoiceCoc: this.form.invoice.coc_number,
            invoiceCocState: this.form.invoice.coc_numberState,
          });
        }
      } else {
        this.form.invoice.coc_numberState = null;
      }
      this.validateInput(event, this.form.invoice.coc_numberState);
    },
    validateInvoiceStreet(event) {
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError =
          this.$t("street_required");
      } else {
        this.form.invoice.address.streetDisplayState = true;
        this.updateLocalStorage({ invoiceAddress: this.form.invoice.address });
      }
      this.validateInput(event, this.form.invoice.address.streetDisplayState);
    },
    validateInvoiceHouseNumber(event) {
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t(
          "house_number_required"
        );
      } else {
        this.form.invoice.address.house_numberState = true;
        this.updateLocalStorage({ invoiceAddress: this.form.invoice.address });
      }
      this.validateInput(event, this.form.invoice.address.house_numberState);
    },
    validateInvoiceCity(event) {
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
        this.updateLocalStorage({ invoiceAddress: this.form.invoice.address });
      }
      this.validateInput(event, this.form.invoice.address.cityState);
    },
    validateInvoicePhone(event) {
      if (this.form.invoice.address.telephone == "") {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
        this.updateLocalStorage({ invoiceAddress: this.form.invoice.address });
      }
      this.validateInput(event, this.form.invoice.address.telephoneState);
    },
    async validateInvoicePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.invoice.address.postcode,
          this.form.invoice.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.invoice.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.invoice.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.invoice.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.invoice.address.city = list[0].city;
          this.form.invoice.address.cityState = true;
          this.foundInvoiceAddress = address;
          this.validateInvoicePostcode();
        } else if (list.length == 0) {
          this.foundInvoiceAddress = this.$t("postcode_not_found");
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            this.foundInvoiceAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError =
            this.$t("postcode_required");
        } else {
          this.form.invoice.address.postcodeState = true;
          this.updateLocalStorage({
            invoiceAddress: this.form.invoice.address,
          });
        }
      }
    },
    validateInvoicePostcode(event) {
      const nl = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      const de = /(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})/g;
      const be = /^[1-9][0-9]{3}$/g;
      if (
        this.form.invoice.address.country_code == "NL" &&
        nl.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
        this.updateLocalStorage({
          invoiceAddress: this.form.invoice.address,
        });
      } else if (
        this.form.invoice.address.country_code == "BE" &&
        be.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
        this.updateLocalStorage({
          invoiceAddress: this.form.invoice.address,
        });
      } else if (
        this.form.invoice.address.country_code == "DE" &&
        de.test(this.form.invoice.address.postcode)
      ) {
        this.form.invoice.address.postcodeState = true;
        this.updateLocalStorage({
          invoiceAddress: this.form.invoice.address,
        });
      } else {
        this.form.invoice.address.postcodeState = false;
        this.form.invoice.address.postcodeError = this.$t("postcode_required");
      }
      this.validateInput(event, this.form.invoice.address.postcodeState);
    },
    validateInput(event, isValid) {
      if (typeof event !== "undefined") {
        if (isValid == true) {
          event.target.parentElement.classList.add("valid");
        } else {
          event.target.parentElement.classList.remove("valid");
        }
      }
    },
    clearLocalStorage() {
      localStorage.removeItem("checkoutAddress");
    },
    checkFields() {
      var form = this.form;
      var errorFields = [];
      var skipFields = [
        "taxvat",
        "streetDisplay",
        "password",
        "password_confirm",
        "coc_number",
      ];
      Object.keys(form).forEach((name) => {
        if (
          typeof form[name] == "object" &&
          !Array.isArray(form[name]) &&
          form[name] !== null
        ) {
          if (name == "invoice" && form.address.default_billing) {
            return;
          }
          Object.keys(form[name]).forEach((child) => {
            if (child == "companyState" && name == "address") {
              if (!this.isCompany) {
                return;
              }
            }
            if (child == "companyState" && name == "invoice") {
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (
              child.includes("State") &&
              (form[name][child] == false || form[name][child] == null)
            ) {
              if (child == "companyState" && name == "address") {
                if (!this.isCompany) {
                  return;
                }
              }
              if (child == "companyState" && name == "invoice") {
                if (!this.isInvoiceCompany) {
                  return;
                }
              }
              if (skipFields.includes(child.replace("State", ""))) {
                return;
              }
              switch (name) {
                case "invoice":
                  child = "invoice_" + child;
                  break;
                case "address":
                  child = "shipping_" + child;
                  break;
              }
              errorFields.push(this.$t(child.replace("State", "")));
            }
          });
        } else {
          if (
            name.includes("State") &&
            (form[name] == false || form[name] == null)
          ) {
            if (name == "companyState") {
              if (!this.isCompany) {
                return;
              }
              if (!this.isInvoiceCompany) {
                return;
              }
            }
            if (
              (name == "passwordState" || name == "password_confirmState") &&
              (!form.emailState || !this.emailExists)
            ) {
              return;
            }
            if (skipFields.includes(name.replace("State", ""))) {
              return;
            }
            errorFields.push(this.$t(name.replace("State", "")));
          }
        }
        if (errorFields.length > 0) {
          this.$store.commit("cart/setStoreValidation", {
            address: errorFields,
          });
        } else {
          this.$store.commit("cart/setStoreValidation", {
            address: [],
          });
        }
      });
    },
    updateLocalStorage(data) {
      this.localStorageData = Object.assign({}, this.localStorageData, data);
      localStorage.setItem(
        "checkoutAddress",
        JSON.stringify(this.localStorageData)
      );
    },
  },
  computed: {
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.country_code;
    },
    address() {
      if (!this.showFullAddress) {
        return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.house_number}|${this.form.address.addition}|${this.form.address.telephone}||${this.form.address.city}`;
      } else {
        return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.house_number}|${this.form.address.addition}|${this.form.address.telephone}|${this.form.address.postcode}|${this.form.address.city}`;
      }
    },
    invoiceAddress() {
      if (!this.showFullInvoiceAddress) {
        return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
      } else {
        return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}|${this.form.invoice.address.postcode}|${this.form.invoice.address.city}`;
      }
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    country() {
      if (config.postcode_validation.enabled) {
        if (this.country) {
          if (!config.postcode_validation.countries.includes(this.country)) {
            this.showFullAddress = true;
          } else {
            this.showFullAddress = false;
          }
        }
      } else {
        this.showFullAddress = true;
      }
      if (this.form.postcode.length > 0) {
        this.validatePostcode();
      }
    },
    address() {
      if (this.form.addressTimer !== null) {
        clearTimeout(this.form.addressTimer);
      }
      this.form.addressTimer = setTimeout(
        function (scope) {
          scope.validateAddress();
        },
        1000,
        this
      );
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function (scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (config.postcode_validation.enabled) {
        if (
          !config.postcode_validation.countries.includes(this.invoiceCountry)
        ) {
          this.showFullInvoiceAddress = true;
        } else {
          this.showFullInvoiceAddress = false;
        }
      } else {
        this.showFullInvoiceAddress = true;
      }
      if (this.form.invoice.address.postcode.length > 0) {
        this.validateInvoicePostcode();
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundInvoiceAddress != "") {
          this.foundInvoiceAddress = "";
        }
        if (this.foundInvoiceAddresses.length > 0) {
          this.foundInvoiceAddresses = [];
        }
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validateInvoicePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    async selectedAddress(newVal) {
      if (newVal !== null) {
        this.form.address.streetDisplay = this.foundAddresses[newVal].street;
        var addition = "";
        if (this.foundAddresses[newVal].letter != null) {
          addition = this.foundAddresses[newVal].letter;
        }
        if (this.foundAddresses[newVal].addition != null) {
          addition +=
            this.foundAddresses[newVal].letter !== null
              ? (!isNaN(this.foundAddresses[newVal].letter) &&
                !isNaN(this.foundAddresses[newVal].addition)
                ? "-"
                : "") + this.foundAddresses[newVal].addition
              : this.foundAddresses[newVal].addition;
        }
        this.form.address.addition = addition;
        this.form.address.city = this.foundAddresses[newVal].city;
        this.form.address.additionDisabled = true;
        await this.validateAddress();
      }
    },
    selectedInvoiceAddress(newVal) {
      this.form.invoice.address.streetDisplay =
        this.foundInvoiceAddresses[newVal].street;
      if (this.foundInvoiceAddresses[newVal].letter != null) {
        this.form.invoice.address.addition =
          this.foundInvoiceAddresses[newVal].letter;
      } else {
        this.form.invoice.address.addition = "";
      }
      this.form.invoice.address.city = this.foundAddresses[newVal].city;
      this.validateInvoicePostcode();
    },
    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
    showFullAddress() {
      this.validateCity();
      if (this.showFullAddress) {
        this.validatePostcode();
        this.validateHouseNumber();
        this.validateCity();
      } else {
        this.validatePostcodeHouseNumber();
      }
      this.validatePhone();
      this.validateAddress();
    },
    showFullInvoiceAddress() {
      this.validateInvoiceCity();
      if (this.showFullInvoiceAddress) {
        this.validateInvoicePostcode();
        this.validateInvoiceHouseNumber();
        this.validateInvoiceCity();
      } else {
        this.validateInvoicePostcodeHouseNumber();
      }
      this.validateInvoicePhone();
      this.validateInvoiceAddress();
    },
  },
  data() {
    return {
      isCompany: false,
      createAccount: false,
      isInvoiceCompany: false,
      isValidVatNumber: false,
      isValidInvoiceVatNumber: false,
      showFullAddress: false,
      showFullInvoiceAddress: false,
      showVatMessage: "",
      showVatCheck: false,
      addressTimer: null,
      invoiceAddressTimer: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      emailExists: false,
      selectedAddress: null,
      selectedInvoiceAddress: null,
      localStorageData: {},
      addressState: {},
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: "",
        addressDisplay: "",
        addressDisplayState: null,
        addressDisplayError: "",
        addressDisplayTimer: null,
        // address: {
        country_code: "",
        postcode: "",
        postcodeState: null,
        postcodeError: "",
        postcodeTimer: null,

        street: "",
        streetDisplay: "",
        streetDisplayState: null,
        streetDisplayError: "",
        streetDisplayTimer: null,

        house_number: "",
        house_numberState: null,
        house_numberError: "",
        house_numberTimer: null,

        addition: "",
        additionDisabled: false,
        city: "",
        cityState: null,
        cityError: "",
        cityTimer: null,

        telephone: "",
        telephoneState: null,
        telephoneError: "",
        telephoneTimer: null,
        default_billing: true,
        default_shipping: true,
        // },
        invoice: {
          isCompany: true,

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,
          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        checkbox2: false,
        manual: false,
      },
      createAccountForm: {
        password: "",
        passwordState: null,
        passwordError: "",
        confirmPassword: "",
        confirmPasswordState: null,
        confirmPasswordError: "",
        isCompany: false,
        companyName: "",
        companyNameState: null,
        companyNameError: "",
        CommerceNumber: "",
        CommerceNumberState: null,
        CommerceNumberError: "",
        vatNumber: "",
        vatNumberState: null,
        vatNumberError: "",
        firstName: "",
        firstNameState: null,
        firstNameError: "",
       lastName: "",
       lastNameState: null,
       lastNameError: "",

      }
    };
  },
};
</script>
